import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { client } from '../apollo/client'
import { CartProvider } from './CartContext'

export const wrapRootElement = ({ element }: { element: React.ReactNode }) => (
  <ApolloProvider client={client}>
    <CartProvider>{element}</CartProvider>
  </ApolloProvider>
)
