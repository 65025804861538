import React, { createContext, useContext, useState, useEffect } from 'react'
import { storefrontClient } from './client'
import {
  CREATE_CART_MUTATION,
  ADD_TO_CART_MUTATION,
  UPDATE_CART_MUTATION,
  REMOVE_FROM_CART_MUTATION,
  GET_CART_QUERY
} from './queries'
import { Cart, CartContextType } from './types'

const CartContext = createContext<CartContextType | undefined>(undefined)
const CART_ID_KEY = 'shopify_cart_id'

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [cart, setCart] = useState<Cart | null>(null)
  const [cartId, setCartId] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  // Helper to validate cart has all required properties
  const isValidCart = (currentCart: Cart | null): currentCart is Cart => {
    return !!(
      currentCart &&
      currentCart.id &&
      currentCart.checkoutUrl &&
      currentCart.cost &&
      currentCart.lines &&
      currentCart.lines.edges
    )
  }

  // Helper to get current cart ID with fallback
  const getCurrentCartId = (): string | null => {
    return cartId || (cart?.id ?? null)
  }

  // Helper for optimistic cart updates
  const updateCartOptimistically = (updater: (currentCart: Cart) => Cart | null) => {
    const currentCart = cart
    if (!isValidCart(currentCart)) return

    const updatedCart = updater({ ...currentCart })
    if (updatedCart) {
      setCart(updatedCart)
    }
  }

  const fetchCart = async (id: string): Promise<void> => {
    setLoading(true)
    try {
      const response = await storefrontClient.request(GET_CART_QUERY, {
        variables: { cartId: id }
      })

      if (response.data?.cart) {
        setCart(response.data.cart)
        setCartId(id)
        localStorage.setItem(CART_ID_KEY, id)
      } else {
        localStorage.removeItem(CART_ID_KEY)
        setCartId(null)
        setCart(null)
      }
    } catch (err) {
      console.error('Error fetching cart:', err)

      // For specific errors, clear cart
      if (err instanceof Error && (err.message.includes('cart not found') || err.message.includes('invalid'))) {
        console.log('Invalid cart ID, clearing state')
        localStorage.removeItem(CART_ID_KEY)
        setCartId(null)
        setCart(null)
      }

      setError(err instanceof Error ? err : new Error('Cart operation failed'))
    } finally {
      setLoading(false)
    }
  }

  // Initialize cart from local storage
  useEffect(() => {
    const storedCartId = localStorage.getItem(CART_ID_KEY)
    if (storedCartId) {
      setCartId(storedCartId)
      fetchCart(storedCartId)
    }
  }, [])

  const createCart = async (): Promise<void> => {
    setLoading(true)
    try {
      const response = await storefrontClient.request(CREATE_CART_MUTATION, {
        variables: {
          input: {}
        }
      })

      if (response.data?.cartCreate?.userErrors?.length > 0) {
        throw new Error(response.data.cartCreate.userErrors[0].message)
      }

      const newCart = response.data?.cartCreate?.cart

      if (newCart) {
        setCart(newCart)
        setCartId(newCart.id)
        localStorage.setItem(CART_ID_KEY, newCart.id)
      }
    } catch (error) {
      console.error('Error creating cart:', error)
      setError(error instanceof Error ? error : new Error('Failed to create cart'))
    } finally {
      setLoading(false)
    }
  }

  const addItemToCart = async (variantId: string, quantity: number): Promise<void> => {
    setLoading(true)
    try {
      let currentCartId: string

      if (!cart) {
        const response = await storefrontClient.request(CREATE_CART_MUTATION, {
          variables: { input: {} }
        })

        if (response.data?.cartCreate?.userErrors?.length > 0) {
          throw new Error(response.data.cartCreate.userErrors[0].message)
        }

        const newCart = response.data?.cartCreate?.cart

        if (!newCart) {
          throw new Error('Failed to create cart')
        }

        setCart(newCart)
        currentCartId = newCart.id
      } else {
        currentCartId = cart.id
      }

      const response = await storefrontClient.request(ADD_TO_CART_MUTATION, {
        variables: {
          cartId: currentCartId,
          lines: [{ merchandiseId: variantId, quantity }]
        }
      })

      if (response.data?.cartLinesAdd?.userErrors?.length > 0) {
        throw new Error(response.data.cartLinesAdd.userErrors[0].message)
      }

      setCart(response.data?.cartLinesAdd?.cart)
    } catch (err) {
      console.error('Error adding item to cart:', err)
      setError(err instanceof Error ? err : new Error('Failed to add item to cart'))
    } finally {
      setLoading(false)
    }
  }

  // Update item quantity
  const updateItemQuantity = async (lineId: string, quantity: number): Promise<void> => {
    const currentCartId = getCurrentCartId()
    if (!currentCartId) {
      console.error('No cart ID available for updating item')
      return
    }

    setLoading(true)
    try {
      // Optimistic update
      updateCartOptimistically((currentCart) => {
        const lineExists = currentCart.lines.edges.some((edge) => edge.node.id === lineId)
        if (!lineExists) {
          console.error(`Line ID ${lineId} not found in cart`)
          return null
        }

        const updatedEdges = currentCart.lines.edges.map((edge) => {
          if (edge.node.id === lineId) {
            return {
              ...edge,
              node: { ...edge.node, quantity }
            }
          }
          return edge
        })

        let newTotalQuantity = 0
        updatedEdges.forEach((edge) => {
          newTotalQuantity += edge.node.quantity
        })

        return {
          ...currentCart,
          lines: { ...currentCart.lines, edges: updatedEdges },
          totalQuantity: newTotalQuantity,
          id: currentCart.id,
          checkoutUrl: currentCart.checkoutUrl,
          cost: currentCart.cost
        }
      })

      // API call
      const response = await storefrontClient.request(UPDATE_CART_MUTATION, {
        variables: {
          cartId: currentCartId,
          lines: [{ id: lineId, quantity: parseInt(quantity.toString(), 10) }]
        }
      })

      if (response.data?.cartLinesUpdate?.userErrors?.length) {
        throw new Error(response.data.cartLinesUpdate.userErrors[0].message)
      }

      const updatedCart = response.data?.cartLinesUpdate?.cart
      if (updatedCart) {
        setCart(updatedCart)
      }
    } catch (err) {
      console.error('Error updating item quantity:', err)

      // On error, refresh cart to get back to a consistent state
      if (currentCartId) {
        fetchCart(currentCartId)
      }

      setError(err instanceof Error ? err : new Error('Failed to update item quantity'))
    } finally {
      setLoading(false)
    }
  }

  // Remove item from cart
  const removeItemFromCart = async (lineId: string): Promise<void> => {
    const currentCartId = getCurrentCartId()
    if (!currentCartId) {
      console.error('No cart ID available for removing item')
      return
    }

    setLoading(true)
    try {
      // Optimistic update
      updateCartOptimistically((currentCart) => {
        const updatedEdges = currentCart.lines.edges.filter((edge) => edge.node.id !== lineId)

        return {
          ...currentCart,
          lines: { ...currentCart.lines, edges: updatedEdges },
          totalQuantity: (currentCart.totalQuantity || 0) - 1,
          id: currentCart.id,
          checkoutUrl: currentCart.checkoutUrl,
          cost: currentCart.cost
        }
      })

      // API call
      const response = await storefrontClient.request(REMOVE_FROM_CART_MUTATION, {
        variables: {
          cartId: currentCartId,
          lineIds: [lineId]
        }
      })

      if (response.data?.cartLinesRemove?.userErrors?.length) {
        throw new Error(response.data.cartLinesRemove.userErrors[0].message)
      }

      const updatedCart = response.data?.cartLinesRemove?.cart
      if (updatedCart) {
        setCart(updatedCart)
      }
    } catch (err) {
      console.error('Error removing item from cart:', err)

      // On error, refresh cart to get back to a consistent state
      if (currentCartId) {
        fetchCart(currentCartId)
      }

      setError(err instanceof Error ? err : new Error('Failed to remove item from cart'))
    } finally {
      setLoading(false)
    }
  }

  const cartCount = cart?.totalQuantity || 0
  const cartItems = cart?.lines?.edges.map((edge) => edge.node) || []
  const checkoutUrl = cart?.checkoutUrl || ''

  const contextValue: CartContextType = {
    cart,
    cartId,
    loading,
    error,
    cartCount,
    cartItems,
    checkoutUrl,
    createCart,
    addItemToCart,
    updateItemQuantity,
    removeItemFromCart
  }

  return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
}

export const useCart = (): CartContextType => {
  const context = useContext(CartContext)
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider')
  }
  return context
}

// Convenience hooks that match the gatsby-theme-shopify-manager API
export const useCartCount = () => useCart().cartCount
export const useCartItems = () => useCart().cartItems
export const useCheckoutUrl = () => useCart().checkoutUrl
export const useAddItemToCart = () => useCart().addItemToCart
export const useUpdateItemQuantity = () => useCart().updateItemQuantity
export const useRemoveItemFromCart = () => useCart().removeItemFromCart
